import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextSnippet } from '@mui/icons-material';
import routes from '../../config/routes';
import { Stages, getStages, Stage } from '../../services/stages';
import DialogError from '../../components/DialogError/DialogError';
import * as Common from '../../components/Styles/Common.styles';
import * as Styles from './CycleBar.styles';
import Button from '@mui/material/Button';

interface CycleBarProps {
  id: string;
  stage: Stages;
}

type StageRoute = Record<number, string>;

const STAGES_ROUTES: StageRoute = {
  0: routes.context,
  1: routes.negotiation,
  2: routes.realization,
  3: routes.evaluation,
};

const CycleBar: React.FC<CycleBarProps> = props => {
  const { id, stage } = props;
  const [stages, setStages] = React.useState<Stage[]>([]);
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const navigate = useNavigate();
  const handleStageSelect = (value: number): void => {
    const stageSelect: Stage | undefined = stages.find(stage => stage.key === value);
    navigate(STAGES_ROUTES[value].replace(/:id/i, id), {
      state: { conversation: { id, stageId: stageSelect?.id } },
    });
  };
  React.useEffect(() => {
    getStages()
      .then((response: Stage[]) => {
        const updatedStages = response
          .filter(stage => stage.key !== undefined)
          .sort((firstItem: Stage, secondItem: Stage) => firstItem.key - secondItem.key);
        setStages(updatedStages);
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudieron obtener las etapas desde la base de datos.');
      });
  }, []);

  return (
    <Styles.Root>
      <Common.GridContainer>
        <Common.GridItem md={2.4} xs={12}>
          <Common.GridContainer>
            <Common.GridItem md={6} xs={6}>
              <Common.GridItem padding="0 55px">
                <TextSnippet color={stage === Stages.context ? 'gi-primary' : 'gi-secondary'} />
              </Common.GridItem>
              <Common.GridItem padding="17px 35px">
                <Styles.Label active={stage === Stages.context}>Contexto</Styles.Label>
              </Common.GridItem>
            </Common.GridItem>
            <Common.GridItem md={6} xs={6}>
              <Button
                variant="text"
                color={stage === Stages.context ? 'gi-primary' : 'gi-secondary'}
                onClick={() => handleStageSelect(0)}
              >
                Prep & contexto
              </Button>
            </Common.GridItem>
          </Common.GridContainer>
        </Common.GridItem>
        <Common.GridItem md={2.4} xs={12}>
          <Common.GridContainer>
            <Common.GridItem md={6} xs={6}>
              <Common.GridItem padding="0 55px">
                <TextSnippet color={stage === Stages.negotiation ? 'gi-primary' : 'gi-secondary'} />
              </Common.GridItem>
              <Common.GridItem padding="17px 35px">
                <Styles.Label active={stage === Stages.negotiation}>Solicitud</Styles.Label>
              </Common.GridItem>
            </Common.GridItem>
            <Common.GridItem md={6} xs={6}>
              <Button
                variant="text"
                color={stage === Stages.negotiation ? 'gi-primary' : 'gi-secondary'}
                onClick={() => handleStageSelect(1)}
              >
                Negociación
              </Button>
            </Common.GridItem>
          </Common.GridContainer>
        </Common.GridItem>
        <Common.GridItem md={2.4} xs={12}>
          <Common.GridContainer>
            <Common.GridItem md={6} xs={6}>
              <Common.GridItem padding="0 65px">
                <TextSnippet color={stage === Stages.realization ? 'gi-primary' : 'gi-secondary'} />
              </Common.GridItem>
              <Common.GridItem padding="17px 30px">
                <Styles.Label active={stage === Stages.realization}>Compromisos</Styles.Label>
              </Common.GridItem>
            </Common.GridItem>
            <Common.GridItem md={6} xs={6}>
              <Button
                variant="text"
                color={stage === Stages.realization ? 'gi-primary' : 'gi-secondary'}
                onClick={() => handleStageSelect(2)}
              >
                Realización
              </Button>
            </Common.GridItem>
          </Common.GridContainer>
        </Common.GridItem>
        <Common.GridItem md={2.8} xs={12}>
          <Common.GridContainer>
            <Common.GridItem md={6} xs={6}>
              <Common.GridItem padding="0 80px">
                <TextSnippet color={stage === Stages.evaluation ? 'gi-primary' : 'gi-secondary'} />
              </Common.GridItem>
              <Common.GridItem padding="17px 50px">
                <Styles.Label active={stage === Stages.evaluation}>Declaración cumplimiento</Styles.Label>
              </Common.GridItem>
            </Common.GridItem>
            <Common.GridItem md={6} xs={6}>
              <Button
                variant="text"
                color={stage === Stages.evaluation ? 'gi-primary' : 'gi-secondary'}
                onClick={() => handleStageSelect(3)}
              >
                Evaluación
              </Button>
            </Common.GridItem>
          </Common.GridContainer>
        </Common.GridItem>
        <Common.GridItem md={2} xs={12}>
          <Common.GridItem md={6} xs={6}>
            <Common.GridItem padding="0 40px">
              <TextSnippet color={stage === Stages.declaration ? 'gi-primary' : 'gi-secondary'} />
            </Common.GridItem>
            <Common.GridItem padding="17px 15px">
              <Styles.Label>Declaración satisfacción</Styles.Label>
            </Common.GridItem>
          </Common.GridItem>
        </Common.GridItem>
      </Common.GridContainer>
      {openDialogError && (
        <DialogError openDialog={openDialogError} message={messageError} setOpenDialog={setOpenDialogError} />
      )}
    </Styles.Root>
  );
};

export default CycleBar;
